import React from "react";
import Image from "next/image";

import Styles from "./Sponsors.styles";
import { SponsorsProps as Props } from "./Sponsors.types";
import CONSTANTS from "config/constants";
import useWindowSize from "hooks/useWindowSize";

import Sponsor1 from "../../../../public/assets/images/Sponsors/sponsor-1.jpg";
import Sponsor2 from "../../../../public/assets/images/Sponsors/sponsor-2.jpg";
import Sponsor3 from "../../../../public/assets/images/Sponsors/sponsor-3.jpg";

const { BREAKPOINTS } = CONSTANTS;
const { tablet } = BREAKPOINTS;

const Sponsors: React.FC<Props> = props => {
  const { className } = props;
  const { width } = useWindowSize();
  const isMobile = width <= tablet;

  return (
    <Styles className={`Sponsors ${className}`}>
      <div className="Sponsors__container">
        <div className="Sponsors__title">
          <h3 className="Sponsors__text">Contamos con el apoyo de:</h3>
        </div>
        <div className="Sponsors__images">
          <div className="Sponsors__image">
            <Image
              alt="PetSafe"
              src={Sponsor1}
              width={isMobile ? 293 : 384}
              height={isMobile ? 350 : 458}
            />
          </div>
          <div className="Sponsors__image">
            <Image
              alt="Pedigree"
              src={Sponsor2}
              width={isMobile ? 293 : 384}
              height={isMobile ? 350 : 458}
            />
          </div>
          <div className="Sponsors__image">
            <Image
              alt="Dogtor's cat"
              src={Sponsor3}
              width={isMobile ? 293 : 384}
              height={isMobile ? 350 : 458}
            />
          </div>
        </div>
      </div>
    </Styles>
  );
};

Sponsors.defaultProps = {
  className: ""
};

export default Sponsors;
