import React, { useMemo, useState, useEffect } from "react";

import Styles from "./Home.styles";
import { HomeProps as Props } from "./Home.types";
import Footer from "components/global/Footer/Footer";
import Navbar from "components/global/Navbar/Navbar";
import FilterSection from "../FilterSection/FilterSection";
import Carousel from "../Carousel/Carousel";
import AboutSection from "components/mosumi/AboutSection/AboutSection";
import CategoryGrid from "components/categoryGrid/CategoryGrid/CategoryGrid";
import { useFetchCategoriesWithProducts } from "services/products/products.service.hooks";
import useCatalogues from "contexts/catalogues/catalogues.hooks";
import useStores from "contexts/stores/stores.context.hooks";
import useVendors from "contexts/vendors/vendors.hooks";
import CONSTANTS from "config/constants";
import Sponsors from "components/sponsors/Sponsors/Sponsors";
import useCities from "contexts/cities/cities.hooks";
import { CategoryWithPets } from "components/categoryGrid/CategoryGrid/CategoryGrid.types";
import { categoriesWithOrder } from "components/home/Home/Home.helpers";

const { PRODUCTS_PER_CATEGORY_REQUEST, CATEGORIES_PER_REQUEST } = CONSTANTS.API;

const Home: React.FC<Props> = props => {
  const { selectedCatalogueId } = useCatalogues();
  const { defaultStore } = useStores();
  const { storeId } = defaultStore ?? {};
  const { selectedVendorId } = useVendors();
  const { selectedCity } = useCities();
  const { id: cityId } = selectedCity ?? {};
  const [categories, setCategories] = useState<CategoryWithPets[]>();

  const categoryResponse = useFetchCategoriesWithProducts({
    catalogueId: selectedCatalogueId,
    storeId: storeId!,
    page: 1,
    vendorId: selectedVendorId,
    size: CATEGORIES_PER_REQUEST,
    productsByGroup: PRODUCTS_PER_CATEGORY_REQUEST,
    cityId
  });

  const { data: categoriesPages } = categoryResponse;

  const categoriesArray = useMemo(() => {
    return categoriesPages?.pages.flatMap(page => page.data);
  }, [categoriesPages]);

  useEffect(() => {
    if (!categoriesArray) return;
    const uniqueCategoriesArray: CategoryWithPets[] = [];
    categoriesArray?.map(category => {
      let existingCategoryIndex: number | null = null;
      uniqueCategoriesArray.map((existingCategory, index) => {
        if (existingCategory.name === category.name) {
          existingCategoryIndex = index;
        }
        return existingCategory;
      });
      if (existingCategoryIndex !== null) {
        uniqueCategoriesArray[existingCategoryIndex] = {
          ...uniqueCategoriesArray[existingCategoryIndex],
          products: [
            ...uniqueCategoriesArray[existingCategoryIndex].products,
            ...category.products
          ]
        };
        return category;
      }
      uniqueCategoriesArray.push(category);
      return category;
    });
    const uniqueCategoriesWithOrder: CategoryWithPets[] = [];
    categoriesWithOrder.forEach(categoryName => {
      const foundCategory = uniqueCategoriesArray.find(
        uniqueCategory =>
          uniqueCategory.name.toLowerCase() === categoryName.toLowerCase()
      );
      if (foundCategory) uniqueCategoriesWithOrder.push(foundCategory);
    });
    setCategories(uniqueCategoriesWithOrder);
  }, [categoriesArray]);

  return (
    <Styles className="Home">
      <header className="Home__header">
        <Navbar />
      </header>
      <main className="Home__main">
        <AboutSection />
        <FilterSection />
        {categories && categories.length ? (
          <CategoryGrid className="CategoryGrid" category={categories?.[0]} />
        ) : null}
        <Carousel className="Home__carousel" infinite />
        {categories?.map((category, index) =>
          index > 0 ? (
            <CategoryGrid
              key={index}
              className="CategoryGrid"
              category={category}
            />
          ) : null
        )}
        <Sponsors />
      </main>
      <footer className="Home__footer">
        <Footer showEllipse />
      </footer>
    </Styles>
  );
};

Home.defaultProps = {};

export default Home;
