import CONSTANTS from "config/constants";
import styled from "styled-components";

import { FilterSectionStyledProps as Props } from "./FilterSection.types";

const { tablet, mobile } = CONSTANTS.BREAKPOINTS;

const FilterSectionStyled = styled.div<Props>`
  padding: 6.4rem 5.6rem;
  padding-bottom: 4rem;
  width: 100%;
  background-color: var(--palette-purple-s66-l84);
  background-image: url(https://res.cloudinary.com/dphyzfzpu/image/upload/v1649340683/Mosumi/banner_mcx8of.png);
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  border-radius: 3.2rem;
  margin-bottom: 6rem;

  @media (max-width: ${tablet}px) {
    padding: 2rem 1.6rem;
    border-radius: 2rem;
    margin-bottom: 0;
    background-size: cover;
  }

  .FilterSection {
    &__title {
      font-size: 4rem;
      font-weight: 600;
      z-index: 1;
      position: relative;

      @media (max-width: ${tablet}px) {
        font-size: 2.4rem;
        margin-bottom: 6rem;
        margin-left: 1rem;
      }

      @media (max-width: ${mobile}px) {
        font-size: 2.4rem;
        max-width: 14rem;
        margin-bottom: 8rem;
      }
    }

    &__images {
      display: flex;
      align-items: flex-end;
      position: absolute;
      bottom: 0;
      right: 4rem;

      @media (max-width: ${tablet}px) {
        bottom: unset;
        top: 2rem;
        right: 0.8rem;
      }

      & > div:first-of-type {
        left: 8rem;

        @media (max-width: ${tablet}px) {
          left: 4.8rem;
        }
      }

      &--cat {
        position: relative;
        width: 22.9rem;
        height: 26.4rem;

        @media (max-width: ${tablet}px) {
          width: 13.3rem;
          height: 18.2rem;
        }
      }
      &--dog {
        position: relative;
        width: 25.4rem;
        height: 38.8rem;

        @media (max-width: ${tablet}px) {
          width: 16rem;
          height: 22.2rem;
        }
      }
    }

    &__footer {
      margin-top: 3.2rem;
      display: flex;
      align-items: center;
      z-index: 1;
      position: relative;

      @media (max-width: ${tablet}px) {
        flex-direction: column;
        margin-top: 1.6rem;
      }

      a {
        color: var(--palette-blue-s51-l16);
        font-size: 1.6rem;
        font-weight: 600;
      }

      &__title {
        font-size: 1.6rem;
        color: var(--palette-white);
        font-weight: 600;
        margin-right: 1.2rem;

        @media (max-width: ${tablet}px) {
          padding-bottom: 0.4rem;
        }
      }
    }
  }
`;

export default FilterSectionStyled;
