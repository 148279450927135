// Carousel helper functions and data

import router from "next/router";
import { Banner } from "artisn/types";
import slugify from "slugify";

import { BannerStore } from "components/home/Carousel/Carousel.types";

const slugifyConfig = {
  lower: true,
  locale: "en",
  strict: true
};

export const goToBannerUrl = async (banner: Banner) => {
  const { product_id: petId, product_name: productName } = banner;
  const { banner_type: type, banner_url: bannerUrl } = banner;
  const { vendor_id: vendorId, stores } = banner;
  const { id: storeId } = stores[0] as BannerStore;
  if (bannerUrl) {
    window.open(bannerUrl, "_blank");
    return;
  }

  if (type === "PRODUCT") {
    if (!productName)
      return console.warn("ProductName contains an empty or null string");
    const sanitizedName = slugify(productName, slugifyConfig);
    router.push(
      `/pets/${petId}/${sanitizedName}?storeId=${storeId}&vendorId=${vendorId}`
    );
  }
};
