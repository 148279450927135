import React from "react";
import Head from "next/head";
import { Store } from "artisn/types";
import { QueryClient } from "react-query";
import { GetStaticProps } from "next";
import { dehydrate } from "react-query/hydration";

import CONSTANTS from "config/constants";
import Home from "components/home/Home/Home";
import { prefetchDefaultStore } from "utils/cache.utils";
import TabsMenu from "components/global/TabsMenu/TabsMenu";
import { prefetchCategoriesWithProducts } from "utils/cache.utils";
import { prefetchBanners, prefetchCategories } from "utils/cache.utils";
import useI18n from "hooks/useI18n";
import { notify } from "utils/common.utils";

const { API, ARTISN } = CONSTANTS;
const { CATEGORIES_PER_REQUEST, PRODUCTS_PER_CATEGORY_REQUEST } = API;
const { DEFAULT_CITY } = ARTISN;
const { DOMAIN } = ARTISN;

interface Props {}

const HomePage = (props: Props) => {
  const t = useI18n();

  return (
    <>
      <Head>
        <title>{t.home.metaTitle} | Adopción de mascotas en linea</title>
        <meta name="description" content={t.home.metaDescription} />
        <meta property="og:title" content={t.home.metaTitle} />
        <meta property="og:type" content="website" />
        <meta property="og:description" content={t.home.metaDescription} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:description" content={t.home.metaDescription} />
        <meta name="twitter:title" content={t.home.metaTitle} />
        <meta
          name="twitter:image"
          content={`${DOMAIN}/assets/images/logo.png`}
        />
      </Head>
      <TabsMenu>
        <Home />
      </TabsMenu>
    </>
  );
};

export const getStaticProps: GetStaticProps = async context => {
  const queryClient = new QueryClient();
  const { id: cityId } = DEFAULT_CITY;

  try {
    // Banners
    const banners = prefetchBanners(queryClient, "simple_commerce_carousel");

    // Default store
    await prefetchDefaultStore(queryClient);
    const defaultStore = queryClient.getQueryData<Store>(["stores", "default"]);

    // Categories
    const categories = prefetchCategories(queryClient, defaultStore!.storeId);

    // Categories with products
    const categoriesWithProducts = prefetchCategoriesWithProducts(queryClient, {
      storeId: defaultStore!.storeId,
      productsByGroup: PRODUCTS_PER_CATEGORY_REQUEST,
      size: CATEGORIES_PER_REQUEST,
      cityId
    });

    await Promise.all([banners, categories, categoriesWithProducts]);
  } catch (e) {
    notify(e, "Prefetch banners, categories and categories with products");
    console.error(e.message);
  }

  return {
    props: {
      dehydratedState: JSON.parse(JSON.stringify(dehydrate(queryClient)))
    },
    revalidate: 60
  };
};

export default HomePage;
