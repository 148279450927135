// CategoryGrid helper functions and data

export const categories = [
  {
    name: "Perros",
    description:
      "Encuentra el amor verdadero empacado en un amigo con nariz húmeda, orejas, cola y mucha energía."
  },
  {
    name: "Gatos",
    description:
      "¿Bigotes, ojos profundos y una gran personalidad? Son los ingredientes perfectos para el compañero de vida ideal."
  },
  {
    name: "Compañeros Inseparables",
    description:
      "Los mejores momentos vienen en paquetes más grandes. Adopta a compañeros inseparables y multiplica la dosis de amor."
  },
  {
    name: "Otros",
    description:
      "Plumas, escamas u orejas largas. Tu amigo ideal puede venir en cualquier tamaño o forma.  ¿Estás listo para sorprenderte?"
  }
];

export const getCategoryDescription = (categoryName: string) => {
  const category = categories.find(
    category => category.name.toLowerCase() === categoryName.toLowerCase()
  );
  return category?.description;
};
