import styled from "styled-components";

import { SponsorsStyledProps as Props } from "./Sponsors.types";
import CONSTANTS from "config/constants";

const { tablet } = CONSTANTS.BREAKPOINTS;

const SponsorsStyled = styled.div<Props>`
  .Sponsors {
    &__container {
      width: 100%;
      height: auto;
      padding: 0 2rem;
      margin-top: 8.8rem;
      margin-bottom: 8rem;

      @media (max-width: ${tablet}px) {
        margin-bottom: 0;
        margin-top: 6rem;
      }
    }

    &__title {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__text {
      font-size: 3.6rem;
      font-weight: 600;

      @media (max-width: ${tablet}px) {
        font-size: 2.4rem;
      }
    }

    &__images {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 5rem;

      @media (max-width: ${tablet}px) {
        display: flex;
        flex-direction: column;
        margin-top: 0;
      }
    }

    &__image {
      border-radius: 2rem;
      overflow: hidden;

      &:not(:first-of-type) {
        margin-left: 2rem;
      }

      @media (max-width: ${tablet}px) {
        padding-bottom: 5rem;
        height: 35rem;
        width: 29.2rem;
        margin: 3rem;
      }
    }
  }
`;

export default SponsorsStyled;
