// Banners service hooks
import { useQuery } from "react-query";

import { fetchBanners } from "./banners.service";
import useVendors from "contexts/vendors/vendors.hooks";
import { notify } from "utils/common.utils";

export const useFetchBanners = (bannerCategoryName: string) => {
  const { selectedVendorId } = useVendors();

  return useQuery(
    ["banners", bannerCategoryName, selectedVendorId],
    () => fetchBanners(bannerCategoryName, selectedVendorId),
    {
      staleTime: 30 * 1000 * 60,
      onError: (e: Error) => {
        if (!e) return;
        notify(e, "Get banners request");
      }
    }
  );
};
