import React from "react";
import Link from "next/link";
import Image from "next/image";

import Styles from "./FilterSection.styles";
import { FilterSectionProps as Props } from "./FilterSection.types";
import FilterSearch from "components/global/FilterSearch/FilterSearch";

import DogImage from "../../../../public/assets/images/AboutUs/filters-dog.png";
import CatImage from "../../../../public/assets/images/AboutUs/filters-cat.png";

const FilterSection: React.FC<Props> = props => {
  const { className } = props;

  return (
    <Styles className={`FilterSection ${className}`}>
      <h2 className="FilterSection__title">Encuentra a tu amigo ideal</h2>
      <FilterSearch redirect />
      <div className="FilterSection__images">
        <div className="FilterSection__images--cat">
          <Image
            alt="Encuentra a tu amigo ideal"
            src={CatImage}
            layout="fill"
          />
        </div>
        <div className="FilterSection__images--dog">
          <Image
            alt="Encuentra a tu amigo ideal"
            src={DogImage}
            layout="fill"
          />
        </div>
      </div>
      <div className="FilterSection__footer">
        <p className="FilterSection__footer__title">
          ¿Necesitas ayuda para elegir?
        </p>
        <Link href="/wizard">Te ayudamos aquí</Link>
      </div>
    </Styles>
  );
};

FilterSection.defaultProps = {
  className: ""
};

export default FilterSection;
