import styled from "styled-components";

import { CategoryGridStyledProps as Props } from "./CategoryGrid.types";
import { CategoryPlaceholderStyledProps as PlaceholderProps } from "./CategoryGrid.types";
import CONSTANTS from "config/constants";

const { desktop, tablet, mobile } = CONSTANTS.BREAKPOINTS;

const CategoryGridStyled = styled.div<Props>`
  @media (max-width: ${tablet}px) {
    padding-left: 1.6rem;
  }

  .CategoryGrid {
    margin-bottom: 10rem;

    &__container {
      margin-top: 8rem;
      background-color: var(--palette-cream-s42-l93);
      border-radius: 3.2rem;

      @media (max-width: ${tablet}px) {
        margin-top: 4rem;
      }

      &__titles {
        display: flex;
      }

      &__title {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2.4rem;
        font-weight: 600;
      }

      &__button {
        border-radius: 1.2rem;
        font-weight: bolder;
        font-size: 1.6rem;
        border: none;
        box-shadow: none;
        white-space: nowrap;
      }

      &__description {
        word-break: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        max-width: 100%;

        @media (max-width: ${tablet}px) {
          padding-right: 3.2rem;
        }
      }
    }

    &__box {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__items {
      width: 100%;
      display: grid;
      grid-template-columns: auto;
      gap: 2rem;
      grid-template-columns: repeat(auto-fit, minmax(28.2rem, 1fr));

      @media (max-width: ${tablet}px) {
        height: 100rem;
      }
    }

    &__button {
      border-radius: 1.2rem;
      font-weight: bolder;
      font-size: 1.6rem;
      margin: 1rem;
      margin-top: 12rem;
      padding: 1.8rem 3rem;

      @media (max-width: ${tablet}px) {
        display: none;
      }
    }
  }

  .ProductCard {
    justify-self: center;
  }
`;

export const CategoryPlaceholderStyled = styled.div<PlaceholderProps>`
  .CategoryPlaceholder {
    width: 100%;

    &__info {
      width: 100%;
      background-color: var(--pallete-cream-s42-l93);
      display: grid;
      gap: 1.5rem;
      grid-template-columns: 15rem 56rem 2fr;
      grid-template-rows: repeat(3, 15rem);
      grid-template-areas:
        "miniBox1 bigBox status"
        "miniBox2 bigBox status"
        "miniBox3 bigBox share";

      @media (max-width: ${desktop}px) {
        justify-content: center;
        display: grid;
        gap: 1.5rem;
        grid-template-columns: repeat(3, 15rem);
        grid-template-rows: repeat(8, 15rem);
        grid-template-areas:
          "bigBox bigBox bigBox"
          "bigBox bigBox bigBox"
          "bigBox bigBox bigBox"
          "miniBox1 miniBox2 miniBox3"
          "status status status"
          "status status status"
          "share share share"
          "share share share";
      }

      @media (max-width: ${mobile}px) {
        margin-top: 10rem;
        grid-template-columns: repeat(3, 9rem);
        grid-template-rows: repeat(10, 9rem);
        grid-template-areas:
          "bigBox bigBox bigBox"
          "bigBox bigBox bigBox"
          "bigBox bigBox bigBox"
          "miniBox1 miniBox2 miniBox3"
          "status status status"
          "status status status"
          "status status status"
          "share share share"
          "share share share"
          "share share share";
      }
    }

    &__info > div {
      border-radius: 2.4rem;
    }

    &__miniBox1 {
      grid-area: miniBox1;
    }

    &__miniBox2 {
      grid-area: miniBox2;
    }

    &__miniBox3 {
      grid-area: miniBox3;
    }

    &__bigBox {
      grid-area: bigBox;
    }

    &__status {
      grid-area: status;

      @media (max-width: ${desktop}px) {
        margin-top: 3rem;
        height: 25rem;
      }

      @media (max-width: ${mobile}px) {
        margin-top: 3rem;
        height: unset;
      }
    }

    &__share {
      grid-area: share;

      @media (max-width: ${desktop}px) {
        margin-top: 3rem;
        height: 18rem;
      }

      @media (max-width: ${mobile}px) {
        margin-top: 3rem;
        height: unset;
      }
    }

    &__recommendations {
      width: 100%;
      margin-top: 10rem;
      display: grid;
      gap: 1rem;
    }

    &__recommendations > div {
      border-radius: 2.4rem;
    }

    &__title {
      width: 20%;
      height: 2.6rem;

      @media (max-width: ${tablet}px) {
        width: 80%;
        height: 2.6rem;
      }
    }

    &__description {
      width: 60%;
      height: 2.6rem;

      @media (max-width: ${mobile}px) {
        width: 100%;
        height: 5.2rem;
      }
    }

    &__container {
      margin-top: 4rem;
      display: grid;
      gap: 4rem;
      grid-template-columns: repeat(3, 28rem);
      grid-template-rows: repeat(2, 40rem);
      justify-content: center;

      @media (max-width: ${desktop}px) {
        grid-template-columns: repeat(2, 28rem);
        grid-template-rows: repeat(3, 40rem);
      }

      @media (max-width: ${tablet}px) {
        grid-template-columns: repeat(1, 28rem);
        grid-template-rows: 40rem;
      }

      @media (max-width: ${mobile}px) {
        grid-template-columns: 1fr;
        grid-template-rows: 40rem;
      }
    }

    &__container > div {
      border-radius: 2.4rem;
    }
  }
`;

export default CategoryGridStyled;
