import React, { useEffect, useState, useRef } from "react";
import { Carousel as CarouselUI, Image } from "artisn-ui-react";

import Styles from "./Carousel.styles";
import { CarouselProps as Props } from "./Carousel.types";
import { useFetchBanners } from "services/banners/banners.service.hooks";
import useIsomorphicLayoutEffect from "hooks/useIsomorphicLayoutEffect";
import useWindowSize from "hooks/useWindowSize";
import { goToBannerUrl } from "./Carousel.helpers";
import CONSTANTS from "config/constants";

const { DEFAULT_IMAGE } = CONSTANTS.ARTISN;

const Carousel: React.FC<Props> = props => {
  const { className } = props;
  const { width: windowWidth } = useWindowSize();
  const [maxWidth, setMaxWidth] = useState(windowWidth);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { data: banners } = useFetchBanners("banner_historias_web");
  const bannersLength = banners?.length;

  useEffect(() => {
    if (!bannersLength)
      console.warn(
        "Warning: Unable to display. There are no images to display on the carousel "
      );
  }, [bannersLength]);

  const images =
    banners?.map(item => {
      return {
        name: item.image_file_name,
        url: item.image_url,
        bucket: item.image_bucket,
        cloudFrontUrl: item.image_cloud_front_url,
        key: item.image_key
      };
    }) ?? [];

  useIsomorphicLayoutEffect(() => {
    if (!wrapperRef.current) {
      return;
    }
    const wrapperWidth = wrapperRef.current?.getBoundingClientRect().width;
    const newMaxWidth = Math.min(wrapperWidth, windowWidth);
    setMaxWidth(newMaxWidth);
  }, [windowWidth]);

  return (
    <Styles ref={wrapperRef} className={`Carousel ${className}`}>
      <h2 className="Carousel__title">Historias y testimonios</h2>
      {bannersLength ? (
        <>
          <CarouselUI {...props} className="Carousel__carousel">
            {banners?.map((banner, index) => {
              return (
                <Image
                  key={index}
                  image={images[index]}
                  config={{
                    width: maxWidth,
                    height: maxWidth / 2.7
                  }}
                  alt={banner.banner_description}
                  placeholder="blur"
                  errorImage={DEFAULT_IMAGE}
                  onClick={() => {
                    goToBannerUrl(banner);
                  }}
                />
              );
            })}
          </CarouselUI>
        </>
      ) : null}
    </Styles>
  );
};

Carousel.defaultProps = {
  className: ""
};

export default Carousel;
