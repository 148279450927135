import React from "react";

import { CategoryPlaceholderStyled as Styles } from "./CategoryGrid.styles";
import { CategoryPlaceholderProps as Props } from "./CategoryGrid.types";

const CategoryPlaceholder: React.FC<Props> = props => {
  return (
    <Styles className="CategoryPlaceholder">
      <div className="CategoryPlaceholder__recommendations">
        <div className="CategoryPlaceholder__title loading-shine" />
        <div className="CategoryPlaceholder__description loading-shine" />
        <div className="CategoryPlaceholder__container">
          <div className="CategoryPlaceholder__item1 loading-shine" />
          <div className="CategoryPlaceholder__item2 loading-shine" />
          <div className="CategoryPlaceholder__item3 loading-shine" />
          <div className="CategoryPlaceholder__item4 loading-shine" />
          <div className="CategoryPlaceholder__item5 loading-shine" />
          <div className="CategoryPlaceholder__item6 loading-shine" />
          <div className="CategoryPlaceholder__item7 loading-shine" />
          <div className="CategoryPlaceholder__item8 loading-shine" />
        </div>
      </div>
    </Styles>
  );
};

CategoryPlaceholder.defaultProps = {};

export default CategoryPlaceholder;
