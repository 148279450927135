// Home helper functions and data

export const homeSliderImageConfig = {
  width: 368,
  height: 488
};

export const homeSliderImageConfigMobile = {
  width: 256,
  height: 340
};

export const categoriesWithOrder: string[] = [
  "gatos",
  "perros",
  "compañeros inseparables",
  "otros"
];
