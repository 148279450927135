import styled from "styled-components";

import { CarouselStyledProps as Props } from "./Carousel.types";
import CONSTANTS from "config/constants";

const { BREAKPOINTS } = CONSTANTS;
const { tablet, mobile } = BREAKPOINTS;

const CarouselStyled = styled.div<Props>`
  .Carousel {
    &__title {
      font-size: 2.4rem;
      font-weight: 600;
      z-index: 1;
      position: relative;
      margin-top: 12rem;

      @media (max-width: ${tablet}px) {
        margin-top: 0;
      }

      @media (max-width: ${mobile}px) {
        display: none;
      }
    }

    &__carousel {
      padding-top: 3.4rem;
      margin-bottom: 12rem;
      background-color: transparent;
      flex: 1;
      cursor: pointer;

      @media (max-width: ${tablet}px) {
        margin-bottom: unset;
      }
    }

    &__inner-item {
      border-radius: 4rem;

      @media (max-width: ${mobile}px) {
        border-radius: 2.4rem;
      }
    }

    .Controls {
      &__arrow {
        top: -1.4rem;
        background-color: var(--palette-purple-s100-l99);
        border-radius: 1.2rem;
        padding: 0.8rem 1.2rem;
        z-index: 1;
        left: 26.4rem;
        width: 4.6rem;
        height: 4.4rem;

        @media (max-width: ${mobile}px) {
          display: none;
        }

        &--left {
          path {
            fill: var(--palette-black);
          }
        }

        &--right {
          margin-left: 6rem;

          @media (max-width: ${mobile}px) {
            display: none;
          }

          path {
            fill: var(--palette-black);
          }
        }
      }
    }

    .Pagination {
      display: none;
    }
  }
`;

export default CarouselStyled;
