import styled from "styled-components";

import { HomeStyledProps as Props } from "./Home.types";
import CONSTANTS from "config/constants";

const { BREAKPOINTS } = CONSTANTS;
const { tablet, mobile } = BREAKPOINTS;

const HomeStyled = styled.div<Props>`
  min-width: var(--sizes-page-minWidth);
  max-width: var(--sizes-page-maxWidth);
  min-height: var(--sizes-page-minHeight);
  background-color: var(--palette-cream-s42-l93);
  display: grid;
  grid-template-columns: var(--sizes-page-columns);
  grid-template-rows: max-content 1fr max-content;

  .Home {
    &__header {
      grid-column: 1 / -1;
    }

    &__main {
      flex: 1;
      display: flex;
      flex-direction: column;
      grid-column: content-start / content-end;
      padding-top: calc(var(--sizes-navbar-height) - env(safe-area-inset-top));

      @media (max-width: ${tablet}px) {
        padding-top: calc(
          var(--sizes-navbar-height-mobile) - env(safe-area-inset-top)
        );
      }

      @media (max-width: ${mobile}px) {
        grid-column: 1 / -1;
      }
    }

    &__carousel {
      position: relative;

      & .Image__img {
        object-fit: cover;
      }
    }

    &__category-slider {
      padding-top: 2.4rem;

      @media (max-width: ${tablet}px) {
        display: none;
      }
    }

    &__category-gallery {
      padding-top: 20rem;

      @media (max-width: ${tablet}px) {
        padding-top: 8rem;
      }

      @media (max-width: ${tablet}px) {
        padding-top: 5.6rem;
      }

      .Image__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__products-slider {
      display: block;
      padding-top: 16rem;
      padding-bottom: 3.2rem;

      @media (max-width: ${tablet}px) {
        display: none;
      }
    }

    &__product-gallery {
      display: none;
      padding-top: 8rem;
      padding-bottom: 2rem;

      @media (max-width: ${tablet}px) {
        padding-top: 5.6rem;
        display: block;
      }
    }

    &__footer {
      grid-column: 1 / -1;
      padding-top: 3.2rem;
    }
  }
`;

export default HomeStyled;
