import React from "react";
import Image from "next/image";
import { useRouter } from "next/router";

import Styles from "./AboutSection.styles";
import { AboutSectionProps as Props } from "./AboutSection.types";
import CONSTANTS from "config/constants";
import useWindowSize from "hooks/useWindowSize";
import Button from "components/global/Button/Button";

import Mascotas from "../../../../public/assets/images/AboutUs/mascotas-quienessomos.png";
import CircleDog from "../../../../public/assets/images/AboutUs/circle-dog.png";
import CircleCat from "../../../../public/assets/images/AboutUs/circle-cat.png";
import CircleHeart from "../../../../public/assets/images/AboutUs/circle-heart.png";
import CircleHeartMini from "../../../../public/assets/images/AboutUs/circle-heart-mini.svg";

const { BREAKPOINTS } = CONSTANTS;
const { tablet } = BREAKPOINTS;

const AboutSection: React.FC<Props> = props => {
  const { className } = props;
  const { width } = useWindowSize();
  const isMobile = width <= tablet;
  const { push } = useRouter();

  return (
    <Styles className={`AboutSection ${className}`}>
      <div className="AboutSection__container">
        <div className="AboutSection__circle-heart-box">
          {isMobile ? (
            <CircleHeartMini />
          ) : (
            <Image src={CircleHeart} alt="Parche con forma de corazón" />
          )}
        </div>
        <div className="AboutSection__description">
          <h2 className="AboutSection__title-1">
            ¡Conoce un poco más acerca de
          </h2>
          <h1 className="AboutSection__title-2">ADOPCIÓNANIMAL.EC!</h1>
          <p className="AboutSection__text">
            Somos una organización sin fines de lucro comprometida con la
            comunidad y con el bienestar de los animales domésticos que no han
            tenido la oportunidad de ser parte de un hogar feliz.
          </p>
          <div className="AboutSection__bottom">
            <Button
              className="AboutSection__button"
              color="primary-light"
              onClick={() => push("/profile/about-us")}
              isLoading={false}
            >
              Saber más
            </Button>
            <Button
              className="AboutSection__button"
              color="black"
              onClick={() => push("/profile/add-shelter")}
              isLoading={false}
            >
              Registra tu albergue
            </Button>
          </div>
        </div>
        {isMobile ? null : (
          <div className="AboutSection__images">
            <div className="AboutSection__circle-dog-box">
              <Image src={CircleDog} alt="Parche con forma de perro" />
            </div>
            <div className="AboutSection__circle-cat-box">
              <Image src={CircleCat} alt="Parche con forma de gato" />
            </div>
            <div className="AboutSection__pets">
              <Image
                className="AboutSection__mascotas-image"
                src={Mascotas}
                alt="Foto de mascotas"
              />
            </div>
          </div>
        )}
      </div>
    </Styles>
  );
};

AboutSection.defaultProps = {
  className: ""
};

export default AboutSection;
