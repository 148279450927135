import React, { useMemo } from "react";
import { Slider } from "artisn-ui-react";
import { useRouter } from "next/router";

import Styles from "./CategoryGrid.styles";
import { BasePet, CategoryGridProps as Props } from "./CategoryGrid.types";
import useWindowSize from "hooks/useWindowSize";
import CONSTANTS from "config/constants";
import ProductCard from "components/global/ProductCard/ProductCard";
import Button from "components/global/Button/Button";
import CategoryPlaceholder from "../CategoryGrid/CategoryPlaceholder";
import ProductError from "../../product/ProductError/ProductError";
import { getCategoryDescription } from "components/categoryGrid/CategoryGrid/CategoryGrid.helpers";

const { BREAKPOINTS } = CONSTANTS;
const { tablet } = BREAKPOINTS;

const CategoryGrid: React.FC<Props> = props => {
  const { isLoading, isError } = props;
  const { category, className } = props;
  const { width } = useWindowSize();
  const isMobile = width <= tablet;
  const { name, products } = category;
  const { push } = useRouter();
  const orderedProducts = useMemo(() => {
    return [...products].sort((product1: BasePet, product2: BasePet) => {
      return (
        (product1.createdAt
          ? new Date(product1.createdAt).getTime()
          : new Date().getTime()) -
        (product2.createdAt
          ? new Date(product2.createdAt).getTime()
          : new Date().getTime())
      );
    });
  }, [products]);
  const description = useMemo(() => {
    return getCategoryDescription(name);
  }, [name]);

  return (
    <Styles className={`CategoryGrid ${className}`}>
      <div
        className={
          !isLoading && !isError ? "CategoryGrid__container" : undefined
        }
      >
        {!isLoading && !isError ? (
          <>
            <div className="CategoryGrid__container__titles">
              <h2 className="CategoryGrid__container__title">{name}</h2>
              <Button
                type="LINK"
                className="CategoryGrid__container__button"
                onClick={() => push("/pets")}
                isLoading={false}
              >
                Ver todos
              </Button>
            </div>
            {description && (
              <p className="CategoryGrid__container__description">
                {description}
              </p>
            )}

            {isMobile ? (
              <Slider gap={24} height={520} slideWidth={282} infinite>
                {orderedProducts?.map(product => {
                  const { productId } = product;

                  return (
                    <ProductCard
                      width={isMobile ? 286 : 282}
                      height={isMobile ? 400 : 400}
                      carousel={!isMobile}
                      product={product}
                      key={productId}
                    />
                  );
                })}
              </Slider>
            ) : (
              <div className="CategoryGrid__box">
                <div className="CategoryGrid__items">
                  {orderedProducts?.map(product => {
                    const { productId } = product;

                    return (
                      <ProductCard
                        width={isMobile ? 286 : 286}
                        height={isMobile ? 400 : 435}
                        carousel={!isMobile}
                        product={product}
                        key={productId}
                        category={category}
                      />
                    );
                  })}
                </div>
              </div>
            )}
            <Button
              color="primary-light"
              className="CategoryGrid__button"
              onClick={() => push("/pets")}
              isLoading={false}
            >
              Ver más Amiguitos
            </Button>
          </>
        ) : null}

        {isLoading && !isError ? <CategoryPlaceholder /> : null}

        {!isLoading && isError ? <ProductError /> : null}
      </div>
    </Styles>
  );
};

CategoryGrid.defaultProps = {};

export default CategoryGrid;
