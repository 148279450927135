// Banners services
import { Banner, Vendor } from "artisn/types";

import CONSTANTS from "config/constants";
import axiosDefault from "utils/axios.utils";
import { shouldMock } from "utils/common.utils";

const { ACCOUNT_ID } = CONSTANTS.ARTISN;

/**
 * Fetches the banners from a given category.
 *
 * @param {string} bannerCategoryName The category name to get the banner data
 * @returns {Banner[]} The list of banners of the given category
 */
export const fetchBanners = async (
  bannerCategoryName: string,
  vendorId: Vendor["id"]
): Promise<Banner[]> => {
  try {
    if (!shouldMock) {
      const { data } = await axiosDefault.get(
        `api/banners?account_id=${ACCOUNT_ID}&vendor_id=${vendorId}&banner_category_name=${bannerCategoryName}`
      );
      return data.data;
    } else {
      const { mockBanners } = await import("./banners.service.mock");
      return await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(mockBanners);
        }, 1000);
      });
    }
  } catch (e) {
    throw new Error(e.message);
  }
};
