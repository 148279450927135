import styled from "styled-components";
import CONSTANTS from "config/constants";

import { AboutSectionStyledProps as Props } from "./AboutSection.types";

const { tablet, mobile } = CONSTANTS.BREAKPOINTS;

const AboutSectionStyled = styled.div<Props>`
  @media (max-width: ${mobile}px) {
    padding: 0 1.6rem;
  }

  .AboutSection {
    &__container {
      margin-top: 5.6rem;
      margin-bottom: 12rem;
      background-color: var(--palette-white);
      display: flex;
      border-radius: 3.2rem;
      overflow: hidden;

      @media (max-width: ${tablet}px) {
        margin-top: 3rem;
        margin-bottom: 2.2rem;
      }
    }

    &__description {
      width: 70%;
      padding: 4rem;
      padding-bottom: 2.4rem;

      @media (max-width: ${tablet}px) {
        padding: 4rem 2rem;
        padding-bottom: 2rem;
        width: 100%;
        align-items: center;
        justify-content: center;
      }
    }

    &__images {
      background: linear-gradient(
        to right,
        var(--palette-white) 0%,
        var(--palette-white) 30%,
        var(--palette-purple-s63-l90) 30%,
        var(--palette-purple-s63-l90) 100%
      );
      width: 30%;
      min-width: 35rem;
      display: flex;
      position: relative;
      align-items: flex-end;
    }

    &__pets {
      display: flex;
      align-items: flex-end;
      max-height: 22rem;
    }

    &__mascotas-image {
      border-radius: inherit;
    }

    &__title-1 {
      font-size: 500;
      color: var(--palette-black);

      @media (max-width: ${tablet}px) {
        font-size: 1.4rem;
      }
    }

    &__title-2 {
      font-weight: 600;
      font-size: 2.4rem;
      padding: 0.4rem 0;
      color: var(--palette-blue-s51-l16);

      @media (max-width: ${tablet}px) {
        font-size: 1.8rem;
        padding: 0.5rem 0 1rem 0;
      }
    }

    &__text {
      padding-bottom: 2rem;
      color: var(--palette-black);

      @media (max-width: ${tablet}px) {
        padding-bottom: 1rem;
        font-size: 1.2rem;
        line-height: 1.8rem;
      }
    }

    &__bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 2rem;

      @media (max-width: ${mobile}px) {
        flex-direction: column;
      }
    }

    &__button {
      border-radius: 10rem;
      font-weight: 600;
      font-size: 1.6rem;
      padding: 1rem 4rem;
      box-shadow: unset;
      white-space: nowrap;

      @media (max-width: ${tablet}px) {
        width: 100%;
        padding: 1rem;
        font-size: 1.4rem;
      }
    }

    &__circle-heart-box {
      position: absolute;
      margin: -4rem 0 0 3rem;
      width: 7.2rem;
      height: 7.2rem;

      @media (max-width: ${tablet}px) {
        margin: -1.5rem 0 0 2rem;
        width: unset;
        height: unset;
      }
    }

    &__circle-dog-box {
      position: absolute;
      left: -1.2rem;
      top: 10rem;
    }

    &__circle-cat-box {
      position: absolute;
      right: 0;
      top: 4rem;
    }
  }
`;

export default AboutSectionStyled;
